




.Calculator {
  display: flex;
  flex-direction: column;
  align-items: center;
justify-content: center;
  font-family: "Lucida Console", "Courier New", monospace;

}




